import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/react/solid"
import { CalendarIcon, ClockIcon, UserIcon } from "@heroicons/react/outline"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import { set, useForm } from "react-hook-form"
import Button from "../components/elements/button"

import ReactPlayer from "react-player"
import HomeSlider from "../components/sliders/common"
import { useState, useRef, useEffect } from "react"
import db from "../firebase-config"
import firebase from "firebase/compat/app"

import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css"
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker"

const Events = ({ data }) => {
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)
  const [event, setEvent] = useState(0)

  const [timeBox, setTimeBox] = useState(false)
  const [eventBox, setEventBox] = useState(false)
  const [dateBox, setDateBox] = useState(false)
  const [guestBox, setGuestBox] = useState(false)

  const [submitted, setSubmitted] = useState(false)

  const [startValue, setStartValue] = useState("09:00")
  const [endValue, setEndValue] = useState("09:00")
  const [eventValue, setEventValue] = useState("Birthday Party")
  const [selectedDay, setSelectedDay] = useState(null)

  const times = {
    startTime: startValue,
    endTime: endValue,
  }

  const eventCategory = {
    eventCategory: eventValue,
  }

  // react hook forms
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  // console.log(data)

  const images = {
    hero2: convertToBgImage(getImage(data.hero2)),
    icon1: getImage(data.icon1),

    // online shop
    grid1: getImage(data.grid1),
    grid2: getImage(data.grid2),
    grid3: getImage(data.grid3),
    grid4: getImage(data.grid4),
    grid5: getImage(data.grid5),
    grid6: getImage(data.grid6),
    grid7: getImage(data.grid7),
    grid8: getImage(data.grid8),
  }

  const gallery = [
    { image: images.grid1 },
    { image: images.grid2 },
    { image: images.grid3 },
    { image: images.grid4 },
    { image: images.grid5 },
    { image: images.grid6 },
    { image: images.grid7 },
    { image: images.grid8 },
  ]

  const events = [
    {
      event: "Birthday Party",
    },
    {
      event: "Gathering",
    },
    {
      event: "Wedding",
    },
    {
      event: "Anniversary ",
    },
    {
      event: "Business Meeting​",
    },
    {
      event: "Other",
    },
  ]
  const startTime = [
    {
      time: "06:30 PM",
    },
    {
      time: "07:00 PM",
    },
    {
      time: "07:30 PM",
    },
    {
      time: "08:00 PM",
    },
    {
      time: "08:30 PM",
    },
    {
      time: "09:00 PM",
    },
    {
      time: "09:30 PM",
    },
    {
      time: "10:00 PM",
    },
  ]
  const endTime = [
    {
      time: "06:30 PM",
    },
    {
      time: "07:00 PM",
    },
    {
      time: "07:30 PM",
    },
    {
      time: "08:00 PM",
    },
    {
      time: "08:30 PM",
    },
    {
      time: "09:00 PM",
    },
    {
      time: "09:30 PM",
    },
    {
      time: "10:00 PM",
    },
  ]

  const features = [
    {
      icon: images.icon1,
      title: "Customizable menu option",
      description:
        "Feel free to design your own menu to have the perfect blend of sweets and savories",
    },
    {
      icon: images.icon1,
      title: "Live entertainment",
      description: "You gotta have music right? What beats live music?",
    },
    {
      icon: images.icon1,
      title: "Up to 100 guests",
      description:
        "Wondering who to cut out the invite list because of space limitations? Well, now you don’t have to!",
    },
    {
      icon: images.icon1,
      title: "Projector, screens, TV's",
      description:
        "You can totally bring your own, but we’ve got you sorted with the gadgets, just FYI!",
    },
  ]
  const timeBoxRef = useRef(null)
  useOutsideAlerter(timeBoxRef)

  const eventRef = useRef(null)
  useOutsideAlerter(eventRef)

  // const dateBoxRef = useRef(null)
  // useOutsideAlerter(dateBoxRef)

  const guestBoxRef = useRef(null)
  useOutsideAlerter(guestBoxRef)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // setTimeBox(false)
          // setEventBox(false)
          // setDateBox(false)
          // setGuestBox(false)
        }
      }

      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  // form submission
  const submitContactRequest = async data => {
    let newData = Object.assign({}, data)
    newData.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    newData.isCompleted = false
    await db.collection("events").add({ ...newData, startTime: startValue, endTime: endValue, event: eventValue })
  }

  const sendGridFirebase = async data => {
    fetch(
      "https://us-central1-vaultcoffee.cloudfunctions.net/events",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ ...data, startTime: startValue, endTime: endValue, event: eventValue }),
      }
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
      })
  }

  const onSubmit = async data => {
    setLoading(true)
    try {
      await submitContactRequest(data)
      await sendGridFirebase(data)
      setLoading(false)
      setSubmitted(true)
      // setSuccess(true)
      setSubmitError(null)
      reset()
    } catch (err) {
      setLoading(false)
      console.log(err.message)
      setSubmitError("Submit Error occurred!")
      // setSuccess(false)
    }
  }

  return (
    <Layout>
      <Seo title="Events" />
      <section>
        <BackgroundImage
          {...images.hero2}
          className="bg-primary-brown py-72 w-full"
        >
          <Fade bottom>
            <div>
              <div className="lg:text-8xl text-7xl text-white text-center font-prata gap-6">
                Private Events.
              </div>
            </div>
          </Fade>
        </BackgroundImage>
      </section>
      <section>
        <div className="">
          <Fade bottom cascade>
            <div className="container mx-auto lg:px-20 px-10 py-24">
              <div className="font-prata  md:text-5xl text-4xl text-center text-black mb-5">
                Here’s what’s up
              </div>
              <div className="font-light  text-black text-base text-center max-w-4xl mx-auto">
                How does fully customizable menu options, onsite baristas &
                chefs to prepare your food fresh, live entertainment & high
                speed wifi sound to you? Sounds good? Great! We were hoping
                you’d feel that way because that is exactly what we’re offering.
                Our cozy cafe is looking forward to hosting your next birthday
                party, business meeting, casual hangout, whatever really ! Use
                the form below or get in touch with us directly.
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section>
        <div className="container mx-auto lg:px-20 px-10 pb-24 overflow-hidden">
          <Fade bottom cascade>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5">
              {features.map((item, index) => {
                return (
                  <div className="bg-primary-brown/50 p-10" key={index}>
                    <div className="flex-justfy-center items-center">
                      <div>
                        <div className="flex items-center justify-center">
                          <div>
                            <GatsbyImage
                              image={item.icon}
                              className="w-20"
                              alt="Vault Coffee"
                            />
                          </div>
                        </div>
                        <div className="font-semibold text-xl text-center 2xl:px-10 mt-5 mb-3">
                          {item.title}
                        </div>
                        <div className=" text-center">{item.description}</div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Fade>
        </div>
      </section>
      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto lg:px-20 px-10 pb-24">
            <Fade bottom>
              <div className="font-prata  md:text-5xl text-4xl text-center text-black mb-14">
                Our Top Features
              </div>
            </Fade>
            {submitted ? (
              <section id="thankyou">
                <div className="p-14 shadow-lg">
                  <div className="flex justify-center">
                    <div>
                      <div className="flex justify-center">
                        <div className="p-10  bg-primary-brown rounded-full relative">
                          <div className="absolute inset-0 flex justify-center items-center w-full h-full">
                            <CheckIcon className=" w-10 text-white" />
                          </div>
                        </div>
                      </div>
                      <div className="mt-8 mb-3 font-semibold text-center">
                        Successfully Submitted
                      </div>
                      <div className="font-light">
                        We will gt back to you with confirmation
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className="w-full sm:shadow-lg p-0 sm:p-10">
                <div className="font-semibold pb-12 text-xl">
                  Select date, time, & guests
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 xl:gap-14 2xl:gap-20 mb-20">
                  {/* date */}
                  <div className="sm:border-r border-black relative">
                    <div className="flex gap-5">
                      <div>
                        <CalendarIcon width={30} className="text-gray-400" />
                      </div>
                      <div>
                        <div className="font-semibold">Date</div>
                        <div
                          className="cursor-pointer"
                        // onClick={() => {
                        //   setDateBox(true)
                        // }}
                        >
                          <input type="date" name="date" className="uppercase focus:outline-none" id=""   {...register("date", {
                            required: true,
                          })} />
                          {errors.date ? (
                            <>
                              <div className="text-xs text-red-400 font-firs-light mt-1">
                                Select a date
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {/* {dateBox && ( */}
                    <div
                      // ref={dateBoxRef}
                      className={dateBox ? "block" : "hidden"}
                    >
                      {/* <div className="p-5 shadow-lg absolute xl:-left-5 z-10 bg-white -left-8 top-16">
                        <div>
                          <Calendar
                            value={selectedDay}
                            onChange={setSelectedDay}
                            shouldHighlightWeekends
                          />
                        </div>
                      </div> */}
                    </div>
                    {/* )} */}
                  </div>
                  {/* time */}
                  <div className="xl:border-r border-black flex relative">
                    <div className="flex gap-5">
                      <div>
                        <ClockIcon width={30} className="text-gray-400" />
                      </div>
                      <div>
                        <div className="font-semibold">Time</div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setTimeBox(true)
                          }}
                        >
                          {startTime[start].time} - {endTime[end].time}
                        </div>
                      </div>
                      {/* {timeBox && ( */}
                      <div className={timeBox ? "block" : "hidden"}>
                        <div
                          className="p-5 shadow-lg absolute left-0 xl:-left-10 bg-white z-10 top-16"
                        // ref={timeBoxRef}
                        >
                          <div className="grid grid-cols-2 gap-8">
                            <div className="w-full ">
                              <div className="font-semibold mb-3 mr-4">
                                Start time
                              </div>
                              <div className="overflow-scroll">
                                <div className="h-40 ">
                                  {startTime.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        <div
                                          className={
                                            index == start
                                              ? "px-3 py-2 cursor-pointer bg-primary-brown text-white duration-200"
                                              : "px-3 py-2 cursor-pointer hover:bg-primary-brown/20 duration-200"
                                          }
                                          key={index}
                                          onClick={() => {
                                            setStart(index)
                                            setStartValue(item.time)
                                            setTimeBox(false)
                                          }}
                                        >
                                          {item.time}
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <div className="font-semibold mb-3">End time</div>
                              <div className="overflow-scroll">
                                <div className="h-40 ">
                                  {endTime.map((item, index) => {
                                    return (
                                      <div
                                        className={
                                          index == end
                                            ? "px-3 py-2 cursor-pointer bg-primary-brown text-white duration-200"
                                            : "px-3 py-2 cursor-pointer hover:bg-primary-brown/20 duration-200"
                                        }
                                        key={index}
                                        onClick={() => {
                                          setEnd(index)
                                          setEndValue(item.time)
                                          setTimeBox(false)
                                        }}
                                      >
                                        {item.time}
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                  {/* event */}
                  <div className="sm:border-r border-black flex relative">
                    <div className="flex gap-5">
                      <div>
                        <CalendarIcon width={30} className="text-gray-400" />
                      </div>
                      <div>
                        <div className="font-semibold" >Event</div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setEventBox(true)
                          }}
                        >
                          {events[event].event}
                        </div>
                      </div>
                    </div>
                    {/* {eventBox && ( */}
                    <div className={eventBox ? "block" : "hidden"}>
                      <div
                        className="p-5 shadow-lg absolute left-0 xl:-left-10 bg-white z-10 top-16"
                        ref={eventRef}
                      >
                        <div className="w-56">
                          <div className="w-full ">
                            <div className="font-semibold mb-3 mr-4">
                              Start event
                            </div>
                            <div className="overflow-scroll" >
                              <div className="h-40 ">
                                {events.map((item, index) => {
                                  return (
                                    <div
                                      className={
                                        index == event
                                          ? "px-3 py-2 cursor-pointer bg-primary-brown text-white duration-200"
                                          : "px-3 py-2 cursor-pointer hover:bg-primary-brown/20 duration-200"
                                      }
                                      key={index}
                                      onClick={() => {
                                        setEvent(index)
                                        setEventValue(item.event)
                                        setEventBox(false)
                                        console.log("hellow")
                                      }}
                                    >
                                      {item.event}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* )} */}
                  </div>

                  <div className="flex relative">
                    <div className="flex gap-5">
                      <div>
                        <UserIcon width={30} className="text-gray-400" />
                      </div>
                      <div>
                        <div className="font-semibold">Guests</div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setGuestBox(true)
                          }}
                        >
                          Add guests
                        </div>
                        {errors.adults ? (
                          <>
                            {errors.adults.message ? (
                              <>
                                <div className="text-xs text-red-400 font-firs-light mt-1">
                                  {errors.adults.message}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="text-xs text-red-400 font-firs-light mt-1">
                                  Atleast one adult required
                                </div>
                              </>
                            )}
                          </>
                        ) : null}
                      </div>
                      {/* {guestBox && ( */}
                      <div
                        // ref={guestBoxRef}
                        className={guestBox ? "block" : "hidden"}
                      >
                        <div className="p-5 shadow-lg absolute left-0 xl:-left-5 z-10 bg-white  top-16">
                          <div className="flex justify-end">
                            <XIcon
                              className="text-black w-4 mb-4  cursor-pointer"
                              onClick={() => setGuestBox(false)}
                            />
                          </div>
                          <div className="mb-8">
                            <div className="mb-2">
                              <div className="font-semibold text-sm ">
                                Adults
                              </div>
                              <div className="font-light text-gray-400 text-xs ">
                                Age 13 and above
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              placeholder="Enter the count"
                              className="py-3 border-b-2 focus:outline-none w-56"
                              name="adults"
                              {...register("adults", {
                                pattern: {
                                  value: /^([1-9][0-9]{0,1}|100)$/i,
                                  message: "Please enter a valid adult count",
                                },
                                required: true,
                              })}
                            />
                          </div>
                          <div className="mb-8">
                            <div className="mb-2">
                              <div className="font-semibold text-sm ">
                                Children
                              </div>
                              <div className="font-light text-gray-400 text-xs ">
                                Age 2 - 12
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              placeholder="Enter the count"
                              className="py-3 border-b-2 focus:outline-none w-56"
                              name="children"
                              {...register("children", {
                              })}
                            />
                          </div>
                          <div className="mb-8">
                            <div className="mb-2">
                              <div className="font-semibold text-sm ">
                                Infants
                              </div>
                              <div className="font-light text-gray-400 text-xs ">
                                Under 2
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              placeholder="Enter the count"
                              className="py-3 border-b-2 focus:outline-none w-56"
                              name="infants"
                              {...register("infants", {
                              })}
                            />
                          </div>

                          <div className="mb-6">
                            <div className="mb-2">
                              <div className="font-semibold text-sm ">Pets</div>
                              <div className="font-light  text-xs underline">
                                Bringing a service animal ?
                              </div>
                            </div>
                            <input
                              type="number"
                              min="0"
                              placeholder="Enter the count"
                              className="py-3 border-b-2 focus:outline-none w-56"
                              name="pets"
                              {...register("pets", {
                              })}
                            />
                          </div>
                          <div className="bg-primary-brown py-3 px-5 text-white inline mt-10s cursor-pointer" onClick={() => setGuestBox(false)}>Done</div>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
                {/* <div className="mb-20">
                  <div className="mb-14">
                    <div className="font-semibold  text-xl ">
                      Build your own event
                    </div>
                  </div>
                  <div className="flex gap-2 mb-6 border-b border-black/20 w-full pb-6">
                    <div>
                      <label className="radio-container">
                        <input
                          type="checkbox"
                          name="checkbox"
                          {...register("customizableMenuOption", {
                          })}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div>
                      <div className="font-semibold">
                        Customizable menu option
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 mb-6 border-b border-black/20 w-full pb-6">
                    <div>
                      <label className="radio-container">
                        <input
                          type="checkbox"
                          name="checkbox"
                          {...register("liveEntertainment", {
                          })}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div>
                      <div className="font-semibold">Live entertainment</div>
                    </div>
                  </div>
                  <div className="flex gap-2 mb-6 border-b border-black/20 w-full pb-6">
                    <div>
                      <label className="radio-container">
                        <input
                          type="checkbox"
                          name="checkbox"
                          {...register("UpTo100Guests", {
                          })}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div>
                      <div className="font-semibold">Up to 100 guests</div>
                    </div>
                  </div>
                  <div className="flex gap-2 mb-6 border-b border-black/20 w-full pb-6">
                    <div>
                      <label className="radio-container">
                        <input
                          type="checkbox"
                          name="checkbox"
                          {...register("Projector", {
                          })}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div>
                      <div className="font-semibold">
                        Projector, screens, TV's
                      </div>

                    </div>
                  </div>
                </div> */}
                <div>
                  <div className="font-semibold pb-12 text-xl">
                    Contact details
                  </div>
                  <div>
                    <div className="font-semibold mb-2">
                      Name <span className="text-red-400">*</span>
                    </div>
                    <input
                      type="text"
                      className="w-full border border-black/50 p-3 focus:outline-none"
                      placeholder="Enter your name"
                      name="name"
                      {...register("name", {
                        required: true,
                        pattern: {
                          value: /^[a-z ,.'-]+$/i,
                          message: "You Can Only Use Letters",
                        },
                      })}
                    />
                    {errors.name ? (
                      <>
                        {errors.name.message ? (
                          <>
                            <div className="text-xs text-red-400 font-firs-light mt-1">
                              {errors.name.message}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-xs text-red-400 font-firs-light mt-1">
                              Name cannot be empty
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="flex md:flex-row flex-col md:gap-5 mt-10">
                    <div className="w-full">
                      <div className="font-semibold mb-2">
                        Email <span className="text-red-400">*</span>
                      </div>
                      <input
                        type="text"
                        className="w-full border border-black/50 p-3 focus:outline-none"
                        placeholder="Enter your email"
                        name="email"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Email address is not valid",
                          },
                        })}
                      />
                      {errors.email ? (
                        <>
                          {errors.email.message ? (
                            <>
                              <div className="text-xs text-red-400 font-firs-light mt-1">
                                {errors.email.message}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="text-xs text-red-400 font-firs-light mt-1">
                                Email cannot be empty
                              </div>
                            </>
                          )}
                        </>
                      ) : null}
                    </div>
                    <div className="w-full md:mt-0 mt-10">
                      <div className="font-semibold mb-2">
                        Contact number <span className="text-red-400">*</span>
                      </div>
                      <input
                        type="text"
                        className="w-full border border-black/50 p-3 focus:outline-none"
                        placeholder="Enter your contact number"
                        name="number"
                        {...register("number", {
                          required: true,
                          pattern: {
                            value:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                            message: "Number is not valid",
                          },
                        })}
                      />
                      {errors.number ? (
                        <>
                          {errors.number.message ? (
                            <>
                              <div className="text-xs text-red-400 font-firs-light mt-1">
                                {errors.number.message}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="text-xs text-red-400 font-firs-light mt-1">
                                Number cannot be empty
                              </div>
                            </>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-10">
                    <div className="font-semibold mb-2">Company (optional)</div>
                    <input
                      type="text"
                      className="w-full border border-black/50 p-3 focus:outline-none"
                      placeholder="Enter your company name"
                      name="companyName"
                      {...register("companyName", {})}
                    />
                  </div>
                  <div className="mt-10">
                    <div className="font-semibold mb-2">Additional notes</div>
                    <textarea
                      type="text"
                      className="w-full border border-black/50 p-3 focus:outline-none resize-none"
                      placeholder="Enter your note"
                      rows="5"
                      name="note"
                      {...register("note", {})}
                    />
                  </div>
                </div>
                <div className="mt-20">
                  <div className="mb-5">
                    <div className="flex gap-5 items-start">
                      <input
                        type="checkbox"
                        className="mt-[5px] accent-primary-brown  w-5 h-5 bg-primary-brown focus:primary-brown"
                        name="checkbox"
                        {...register("termsAndConditions", {
                          required: true,
                        })}
                      />

                      <div>
                        By ticking, you are confirming that you have read,
                        understood and agree to Vault Coffee reservation
                        <div className="underline cursor-pointer">
                          terms and conditions.{" "}
                        </div>
                      </div>
                    </div>
                    {errors.termsAndConditions ? (
                      <>
                        <div className="text-xs text-red-400 font-firs-light mt-1">
                          Please acceppt our Terms and Conditions
                        </div>
                      </>
                    ) : null}
                  </div>
                  <button
                    className="bg-primary-brown px-10 py-3 border border-primary-brown text-white hover:px-12 duration-500"
                    type="submit"
                  >
                    {loading ? (
                      <div>
                        <Spinner />
                      </div>
                    ) : (
                      <div>Submit</div>
                    )}
                  </button>
                </div>
              </section>
            )}
          </div>
        </form>
      </section>
      <section className="relative">
        <div className="container mx-auto px-20 py-24 ">
          <div className="bg-primary-brown/20 absolute h-full w-full left-0 top-0"></div>
          <Fade bottom cascade>
            <div>
              <div className="font-prata  md:text-5xl text-4xl text-center text-black mb-5">
                Our sweet gallery
              </div>
            </div>
            <div>
              <div className="font-light  text-black text-base text-center">
                Here’s a glimpse of the good times our guests have had in our
                cozy cafe!
              </div>
            </div>
          </Fade>
          <Fade bottom cascade>
            <div className="grid gap-5 md:grid-cols-4 grid-cols-2 mt-14">
              {gallery.map((item, index) => {
                return (
                  <GatsbyImage
                    key={index}
                    image={item.image}
                    alt="Vault Coffee"
                  />
                )
              })}
            </div>
          </Fade>
          <Fade bottom>
            <div className="flex justify-center mt-14">
              <Link to="/about/"><Button bordered color="primary-brown" text="Learn More" /></Link>
            </div>
          </Fade>
        </div>
      </section>
    </Layout>
  )
}

export default Events

const Spinner = () => {
  return (
    <>
      <svg
        role="status"
        className="inline w-5 h-5 text-white animate-spin dark:text-white/50 fill-white/50 dark:fill-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </>
  )
}

export const query = graphql`
  query eventImages {
    hero2: file(relativePath: { eq: "pages/home/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    icon1: file(relativePath: { eq: "pages/events/icon1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    
    
    grid1: file(
      relativePath: { eq: "pages/home/online-shop-slider/001.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid2: file(
      relativePath: { eq: "pages/home/online-shop-slider/002.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid3: file(
      relativePath: { eq: "pages/home/online-shop-slider/003.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid4: file(
      relativePath: { eq: "pages/home/online-shop-slider/004.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid5: file(
      relativePath: { eq: "pages/home/online-shop-slider/005.png" }
    ) { childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid6: file(
      relativePath: { eq: "pages/home/online-shop-slider/006.png" }
    ) {childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid6: file(
      relativePath: { eq: "pages/home/online-shop-slider/006.png" }
    ) {childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    grid7: file(
      relativePath: { eq: "pages/home/online-shop-slider/007.png" }
    ) {childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    grid8: file( relativePath: { eq: "pages/home/online-shop-slider/008.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    

  }
`
